<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="p-2">
        <div class="fl-te-c mb-3">
            <h4>Send to Vendor</h4>
            <btn size="sm" icon="fa fa-history" @click="$router.push('/purchase/order/' + id + '/fa-history/')"
                 text="History"></btn>
        </div>
        <div v-if="data" class="card bs-4 p-0 mb-4 pb-3">
            <div class="fl-te-c mb-3 bb-1 p-3">
                <h5>Order Details</h5>
                <div class="btn-group">
                    <btn size="sm" class="btn-basic-b" @click="$router.push('/purchase/order/list/')"
                         text="Back"></btn>
                    <btn v-if="data.status==='FA Approved'" size="sm" :loading="loading" loading-text="Submitting..." @click="submitClicked"
                         text="Submit"></btn>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Purchase Order ID</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.order_id }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Created by</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_by }} </div>
                <div class="col-2"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.status }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_date }} </div>
                <div class="col-2"></div>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data.items.length!==0" :data="data.items" :fields="fields" ref="table">
                <template #expected_date="{rowData}">
                    <validated-date-picker v-if="data.status==='FA Approved'" class="mb-4" name="Expected Date" v-model="rowData.expected_date"
                                           placeholder="Enter Date" :rules="{required: false}"
                                           prepend-icon="ni ni-calendar-grid-58" :disabled-date="disabledBeforeToday"/>
                    <span v-else>{{ rowData.expected_date }}</span>
                </template>
                <template #remarks="{rowData}">
                    <validated-input v-if="data.status==='FA Approved'" placeholder="Enter Remarks" name="Remarks" v-model="rowData.remarks"
                                     :rules="{required: false}"/>
                    <span v-else>{{ rowData.sent_to_vendor_remarks }}</span>
                </template>
            </custom-simple-table>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    name : 'DetailsPage',
    data () {
        const now = new Date();
        const month = now.getMonth() + 1;
        const minDate = now.getFullYear() + '-' + month + '-' + now.getDate();
        return {
            DataLoading : false,
            loading     : false,
            id          : this.$route.params.id,
            data        : '',
            min         : minDate,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'product',
                    sortField : 'product',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                },
                {
                    name  : '__slot:expected_date',
                    title : 'Expected Date'
                },
                {
                    name  : '__slot:remarks',
                    title : 'Remarks'
                }

            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        disabledBeforeToday (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseOrderFA.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
        },
        async submitClicked () {
            const dict = {};
            dict.expected_date = this.data.items[0].expected_date;
            dict.id = this.id;
            dict.remarks = this.data.items[0].remarks;
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseOrderFA.submitOrder, dict);
            const json = response.data;
            if (json.error === false) {
                that.$notify('Purchase order finalized', 'Success', {
                    type : 'success'
                });
                that.loading = false;
                that.$router.push('/purchase/order/list/');
            } else if (json.errors) {
                that.$notify('Please fill all the fields', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to finalize the request.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
